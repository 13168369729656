import React from 'react';
import { useParams,Link } from "react-router-dom";
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import {Carousel} from 'react-bootstrap';
import { getDataById } from '../data';

var img1= require('./../../images/banner/bnr1.webp');

function Shopproduct() {
	const { id } = useParams();
    const data = getDataById(id);
	
		return(
			<>
				<Header />
				<div className="page-content bg-white">
					
					<div className="dlab-bnr-inr overlay-black-middle" style={{backgroundImage:"url(" + img1 + ")"}}>
						<div className="container">
							<div className="dlab-bnr-inr-entry">
								<h1 className="text-white">Shop Details</h1>
								
								<div className="breadcrumb-row">
									<ul className="list-inline">
										<li><Link to={'./'}><i className="fa fa-home"></i></Link></li>
										<li>Shop Details</li>
									</ul>
								</div>
								
							</div>
						</div>
					</div>
					
					<div className="content-block">
						
						<div className="section-full content-inner-1 bg-gray-light">
							<div className="container woo-entry">
								<div className="row">
									<div className="col-lg-6 m-b30">
										<div className="product-gallery on-show-slider lightgallery" id="lightgallery"> 
											<div className="dlab-box">
												<div className="dlab-thum-bx">
												<Carousel >{
												data.images.map(images =>
												<Carousel.Item interval={2500}>
                                				<img  src={images} alt="" />
												</Carousel.Item>
												  )
												}
												</Carousel>
												</div>
											</div>
										</div>
									</div>
									<div className="col-lg-6 m-b30">
										<form method="post" className="cart sticky-top">
										<div className="dlab-post-title">
											<h4 className="post-title">{data.title}</h4>
											<p className="m-b10">{data.yachtLength} | {data.capacity} | {data.feature1}</p>
											<p className="m-b10">Inclusions: {data.inclusions}</p>
											<div className="dlab-divider bg-gray tb15">
												<i className="icon-dot c-square"></i>
											</div>
										</div>
										<div className="relative">
											<h3 className="m-tb10">{data.price}</h3>
											<div className="shop-item-rating">
												<span className="rating-bx"> 
													<i className="fa fa-star"></i> 
													<i className="fa fa-star"></i> 
													<i className="fa fa-star"></i> 
													<i className="fa fa-star"></i> 
													<i className="fa fa-star"></i> 
												</span>
												<span>5.0 Rating</span>
											</div>
										</div>
										
										<div className="dlab-divider bg-gray tb15">
											<i className="icon-dot c-square"></i>
										</div>
										<a href={'https://wa.me/971567033102'} target="_blank" rel="noopener noreferrer" className="btn btnhover"><i className="fa fa-whatsapp m-r5"></i>Confirm Now </a>
									</form>
									</div>
								</div>
								
							</div>
						</div>
						
						
					</div>
				</div>
				
				
				<Footer  />
				
				</>
			)
}
 
export default Shopproduct; 