import React, {Component} from 'react';

import { Link } from 'react-router-dom';
import GoogleMaps from "simple-react-google-maps";
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import {Helmet} from "react-helmet";


var bgimg1 = require('./../../images/banner/bnr4.webp');
var bgimg2 = require('./../../images/background/bg5.webp');

class Contact extends Component{
	
	render(){
		return(
			<>
			<Helmet>
					<title>Habibi Yachts | Contact Us</title>
					<meta name="description" content="Habibi Yachts are not just a yacht rental company, we are an extraordinary luxury event & luxury yacht rental planning company with a complete package of moments and celebrations under one platform. We have UAE's best Yachts Charter services with  Sunseeker & Benetti Yachts. Jetski & Seabob. " />
					<meta name="keywords" content="habibi Yachts, habibi Yachts Dubai,habibi.com,habibiyachts.com,yachts,xclusive yachts,yachts in dubai marina,princess yachts,marina yachts,
        dubai marina yachts,luxury yachts,majesty yachts,azimut yachts,royal yachts,yachts dubai,luxurious yachts,
        yachts in dubai,yachts for rent,cozmo yachts,superyacht,boat charter,mega yacht,yacht charter,sailing yacht
        yacht broker,birthday parties on yachts,birthday yachts,birthday in yacht,happy birthday yachts,birthday party in yacht dubai,
        wedding yachts miami,wedding on a yacht dubai,wedding charter yachts,wedding yachts in miami,wedding yacht dubai,
        mega yachts dubai,mega yachts for charter,mega yachts and superyachts,fishing in dubai,fishing abu dhabiyachts rental dubai,yachts rental,yachts rental near me,yachts rental in miami,adonia yachts rental lake powell,
adonia yachts rental,ariel yachts rental,azimut yachts rental,a yacht rental,yachts rental miami beach,yachts and boats rental dubai,nanje rental yachts dubai,
yachts to rent in dubai,yacht rental uae,yachts for rental,yachts for rental near me,yachts for rental in miami,yachts for rental in dubai,
luxury yachts for rental,yachts rental in dubai,yachts rental in abu dhabi,yacht rent dubai,yacht charters dubai,luxury yachts rental,
yachts rental miami,luxury yachts rental miami,luxury yachts rental marina,mega yachts rental,yacht rent in abu dhabi,party yacht rental,yachts rentals,
yachts rentals near me,yachts rentals dubai,vacation rental yachts,wajer yachts rental,xclusive yachts rental dubai,yacht booking abu dhabi,yacht to rent dubai,
yachts dubai rent" />
				</Helmet>
			
			<Header />
			<div className="page-content bg-white">
				<div className="dlab-bnr-inr overlay-black-middle" style={{backgroundImage: "url(" + bgimg1 + ")"}}>
					<div className="container">
						<div className="dlab-bnr-inr-entry">
							<h1 className="text-white">Contact</h1>
							
							<div className="breadcrumb-row">
								<ul className="list-inline">
									<li><Link to={'./'}><i className="fa fa-home"></i></Link></li>
									<li>Contact</li>
								</ul>
							</div>
							
						</div>
					</div>
				</div>
				<div className="content-block">
					<div className="section-full content-inner-2 contact-form bg-white" style={{backgroundImage:"url(" +  bgimg2 + ")", backgroundSize:"100%" }}>
						<div className="container">
							<div className="row">
								<div className="col-xl-6 col-lg-6 col-md-6 d-flex m-md-b30 m-lg-b30">
									<div className="p-a30 border contact-area border-1 align-self-stretch radius-sm bg-white">
										<h3 className="m-b5">Quick Contact</h3>
										<p>If you have any questions simply use the following contact details.</p>
										<ul className="no-margin">
											<li className="icon-bx-wraper left m-b30">
												<div className="icon-bx-xs border-1 text-primary"> <Link to={''} className="icon-cell"><i className="ti-location-pin"></i></Link> </div>
												<div className="icon-content">
													<h6 className="text-uppercase m-tb0 dlab-tilte">Address:</h6>
													<p>Dubai Marina, UAE</p>
												</div>
											</li>
											<li className="icon-bx-wraper left  m-b30">
												<div className="icon-bx-xs border-1 text-primary"> <a href={"mailto:hababiyachts@gmail.com"} target="_blank" rel="noopener noreferrer" className="icon-cell"><i className="ti-email"></i></a> </div>
												<div className="icon-content">
													<h6 className="text-uppercase m-tb0 dlab-tilte">Email:</h6>
													<p>hababiyachts@gmail.com</p>
												</div>
											</li>
											<li className="icon-bx-wraper left m-b30">
												<div className="icon-bx-xs border-1 text-primary"><a href={'tel:+971567033102'} className="icon-cell"><i className="ti-mobile"></i></a> </div>
												<div className="icon-content">
													<h6 className="text-uppercase m-tb0 dlab-tilte">PHONE</h6>
													<p>056 703 3102</p>
												</div>
											</li>
											<li className="icon-bx-wraper left m-b30">
												<div className="icon-bx-xs border-1 text-primary"><a href={'https://wa.me/971567033102'} target="_blank" rel="noopener noreferrer" className="fa fa-whatsapp "><i className="ti-whatsapp"></i></a> </div>
												<div className="icon-content">
													<h6 className="text-uppercase m-tb0 dlab-tilte">Whatsapp </h6>
													<p>(+971) 56 703 3102</p>
												</div>
											</li>
										</ul>
										<div className="m-t20">
											<ul className="dlab-social-icon dlab-social-icon-lg">
												<li><a href={'https://www.facebook.com/habibiyachtsevents'} target="_blank" rel="noopener noreferrer" className="fa fa-facebook bg-primary mr-1"> </a></li>
												<li ><a  href={'https://wa.me/971567033102'} target="_blank" rel="noopener noreferrer" className="fa fa-whatsapp bg-primary mr-1"> </a></li>
												<li><a href={'https://instagram.com/habibi_yachts_events'} target="_blank" rel="noopener noreferrer" className="fa fa-instagram bg-primary mr-1"> </a></li>
											</ul>
										</div>
									</div>
								</div>
								
								<div className="col-xl-6 col-lg-12 d-flex">
									<GoogleMaps
										apiKey={"AIzaSyBbHk3eFodSk_DSGzv8dd_dIJpZvVDyg4s "}
										style={{ height: "500px", width: "100%" }}
										zoom={6}
										center={{ lat: 25.0805, lng: 55.1403 }}
										markers={{ lat: 25.0805, lng: 55.1403 }} //optional
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
			</>
		)
	}	
}


export default Contact;